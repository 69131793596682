import React from "react";
import d from "./img/d.svg";
import t from "./img/t.svg";


export const SocialIcons = () => {
	const social_info = [
		{ to: "https://x.com/_MadGoblins_", title: "Twitter", icon: t },
		{ to: "https://discord.gg/xx9QqMYKNw", title: "Discord", icon: d },
	];

	return (
		<div className="social">
			{social_info.map((s, i) => (
				<a
					key={i}
					className="social__icon"
					href={s.to}
					target="_blank"
					rel="noreferrer"
				>
					<img src={s.icon} alt={s.title} />
				</a>
			))}
		</div>
	);
};