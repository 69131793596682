import React, { useState } from "react";
import { Paragraph, Title } from "../../components";

const Accordion = ({ question, answer }) => {
	const [isOpen, setIsOpen] = useState(false);
	const open_class = isOpen ? "open" : "";

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={`faq__block-accordion ${open_class}`}>
			<Title
				variant="h3"
				title_class="faq__block-accordion-title"
				text={question}
				action={handleClick}
			/>
			<Paragraph
				paragraph_class="faq__block-accordion-paragraph"
				text={answer}
			/>
		</div>
	);
};

export const FAQ = () => {
	return (
		<div className="faq">
			<Title
				variant="h2"
				title_class="faq__title"
				text="Frequently asked questions"
				animation={{ bottom: true }}
			/>
			<Paragraph
				paragraph_class="faq__paragraph"
				text="We tried to describe everything in the best way possible, but if you have any questions regarding the project or you maybe want to clarify something then these answers on frequently asked questions are right for you.
"
			/>
			<div className="faq__block">
				<Accordion
					question="What is Mad Goblins?"
					answer="Mad Goblins is a unique collection of 4,000 NFTs on the Solana blockchain, with 500 rare Goblins and exciting rewards. The project features its own token (WHOA) and thrilling mystery boxes. Mad Goblins is community-driven, where every holder plays a role in shaping the future of the project."
				/>
				<Accordion
					question="How many Goblins are there?"
					answer="There are only 4,000 Goblins, each unique, with three different rarity levels: COMMON, EPIC, and LEGENDARY."
				/>
				<Accordion
					question="What is the price of each Goblin?"
					answer="Our main goal is to build a strong community that believes in the project. Therefore, we’ve decided to keep the price of each Goblin accessible to everyone. However, we have not yet set a precise price. Those on the whitelist will be able to purchase a Goblin at half the sale price."
				/>
				<Accordion
					question="When will the Goblins be revealed?"
					answer="The Goblins will be revealed 72 hours after the start of the sale."
				/>
				<Accordion
					question="What are the chances of getting one of the rarest Goblins with a single purchase?"
					answer="1:8 (12.5%) In other words, for every 8 purchases, you can expect to get a rare Goblin on average once."
				/>
				<Accordion
					question="What do the Mystery Boxes contain?"
					answer="They may contain SOL, WHOA, NFTs, and exclusive merchandise related to the project."
				/>
				<Accordion
					question="How can I open the Mystery Boxes?"
					answer="First, you need to own one of the 500 rarest Goblins. Then, you can open the Mystery Boxes via a page on our website. The Mystery Boxes can only be opened once at least 30% of the Goblins have been minted."
				/>
			</div>
		</div>
	);
};