import React from "react";
import { Fade } from 'react-awesome-reveal';
import { SocialIcons } from "../../components";



const FooterCreate = () => {
	return (
		<div className="footer__create">
			<span>
				© Copyrights 2024. MAD GOBLINS. All rights reserved.
			</span>
		</div>
	);
};

export const Footer = () => {
	return (
		<footer className="footer">
			<Fade left distance="20px">
				<SocialIcons />
			</Fade>
			<Fade right distance="20px">
				
			</Fade>

				<FooterCreate />

		</footer>
	);
};