import React from "react";
import { Title } from "../../components";
import heroImg1 from "../../assets/heroImg1.png"; 

export const Home = () => {

	return (
		<header className="home">
			<Title
				variant="h1"
				title_class="home__title"
				text="aurrgh Grot Whooooaaaa!"
				animation={{ left: true, distance: "20px" }}
			/>

			<img
				className="home__footer-image"
				src={heroImg1}
				alt="Mad Goblins"
			/>
		
		</header>
	);
};