import React from "react";
import { Paragraph, Steps, Title } from "../../components";

export const Roadmap = () => {
	return (
		<div className="roadmap">
			<Title
				variant="h2"
				text="Roadmap"
				title_class="roadmap__title"
				animation={{ bottom: true }}
			/>

			<Paragraph
				paragraph_class="roadmap__paragraph"
				text=""
			/>

			<Steps />
		</div>
	);
};
