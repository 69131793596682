import React from 'react';
import ReactDOM from 'react-dom/client';
import "./source/scss/index.scss";
import { App } from "./App";
import RoutesComp from "./routes";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RoutesComp comp={App} />
    <App />
  </React.StrictMode>
);