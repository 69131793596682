import React from "react";
import { Paragraph, RuleBlock, Title } from "../../components";

export const About = () => {
	return (
		<div className="about">
			<Title
				variant="h2"
				text="welcome"
				title_class="about__title"
				animation={{ bottom: true }}
			/>

			<Paragraph
				paragraph_class="about__paragraph"
				text="Mad Goblins is a bold collection of 4,000 NFTs on the Solana blockchain. Forget empty promises and unrealistic roadmaps: Mad Goblins is all about making a strong and immediate impact."
			/>

            <Paragraph
				paragraph_class="about__paragraph"
				text="From the start, we’ll reward the lucky holders of the 500 rarest Goblins. But that’s just the beginning... our project includes its very own token WHOA, with 20% of all mint proceeds going directly into a liquidity pool to strengthen the community's foundation."
			/>

            <Paragraph
				paragraph_class="about__paragraph"
				text="Our mission is to build a strong, united community where every Goblin holder has a voice. As a holder, you’re not just an investor, you’re a collaborator shaping the future of the project. Together we’ll decide on key developments and take Mad Goblins to new heights."
			/>

            <Paragraph
				paragraph_class="about__paragraph"
				text="Owning a Goblin means being an integral part of this crazy journey. It’s more than an NFT... it’s your ticket to a thriving, collaborative adventure."
			/>

            <Paragraph
				paragraph_class="about__paragraph"
				text="Want to join the project? Secure your spot on the whitelist and follow us on our socials to stay updated!"
			/>

			<RuleBlock />
		</div>
	);
};