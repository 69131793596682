import React from "react";
import { Paragraph, Title } from "../../components";

import p1 from "./img/1.jpg";
import p2 from "./img/2.jpg";
import iconT from "./img/t.svg";

const TeamImgs = () => {
	const team = [
		{
			title: "rewin",
			text: "Artist & Developer",
			img: p1,
			link: "https://x.com/0xrewin",
		},
		{
			title: "Nic",
			text: "Collab & Community Manager",
			img: p2,
			link: "https://x.com/NFTItalianlover",
		},
	];

	return (
		<div className="team__imgs">
			{team.map((member, index) => (
				<div key={index} className="team__imgs-card">
					<img
						className="team__imgs-card-img"
						src={member.img}
						alt={`${member.title} card`}
					/>
					<Title
						variant="h3"
						title_class="team__imgs-card-title"
						text={member.title}
						animation={{ bottom: true }}
					/>
					<Paragraph
						paragraph_class="team__imgs-card-text"
						text={member.text}
					/>
					<div className="team__imgs-card-icon">
						<a
							href={member.link}  
							target="_blank"      
							rel="noopener noreferrer" 
						>
							<img src={iconT} alt="Icon" className="team__imgs-card-icon-img" />
						</a>
					</div>
				</div>
			))}
		</div>
	);
};

export const Team = () => {
	return (
		<div className="team">
			<Title
				variant="h2"
				title_class="team__title"
				text="Team"
				animation={{ bottom: true }}
			/>
			<Paragraph
				paragraph_class="team__paragraph"
				text="Who’s behind this project? Two crazy folks who love Web3 and have been working in the space for years!"
			/>
			<TeamImgs />
		</div>
	);
};