import React from "react";
import { Link as LinkScroll } from "react-scroll";
import fullLogo from "../../assets/logo.svg";

export const Logo = () => {
	return (
		<LinkScroll
			to="home"
			className="logo"
			spy={true}
			smooth={true}
			duration={1500}
			offset={-50}
		>
			<img
				src={fullLogo}
				alt="Mad Goblins"
				className="logo__image"
			/>
		</LinkScroll>
	);
};