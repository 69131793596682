import React from "react";
import { Paragraph, Title } from "..";

// images
import first from "./img/traits.gif";
import second from "./img/mystery-box.jpg";
import third from "./img/coin.png";

export const RuleBlock = () => {
	const imgs_block = [
		{
			img: first,
			number: "RARITY",
			text: [
				"With over 100 traits spread across 7 categories, the possibilities for combinations are truly endless! Every Goblin is unique, but some are rarer than others.",
				"We have introduced 3 distinct rarity levels:",
				"COMMON - 3,500 Goblins <br /> EPIC - 490 Goblins <br /> LEGENDARY - 10 Goblins"
			],
			animation: { right: true, distance: "20px" },
		},
		{
			img: second,
			number: "MYSTERY BOX",
			text: [
				"If you have an Epic or Legendary Goblin, you can open the Mystery Boxes.",
				"There are 550 Mystery Boxes in total.",
				"500 are assigned one per each Goblin (490 Epic + 10 Legendary). <br /> In addition, 10 special boxes are reserved exclusively for Legendary Goblins, containing richer prizes. <br /> The remaining 40 boxes will be gifted to the community (Some of these boxes contain guaranteed prizes in SOL)"
			],
			animation: {
				left: true,
				distance: "20px",
			},
		},
		{
			img: third,
			number: "WHOA",
			text: [
				"WHOA is the token of this crazy project! The total supply is 3 billion WHOA tokens, which will be distributed as follows:",
				"-50% Distributed to the Community (through Mystery Boxes, Airdrops, and Staking)",
				"-40% Liquidity Pool",
				"-10% Reserved for Future Collaborations, Marketing, and Partnerships",
				"To ensure the stability of the token's price and liquidity, 20% of the total supply will initially be placed in the liquidity pool. Over the next 12 months, liquidity will gradually increase, ultimately reaching 40% of the total supply."
			],
			animation: {
				right: true,
				distance: "20px",
			},
		},
	];

	return (
		<div className="rule">
			{imgs_block.map((img, i) => (
				<div key={i} className="rule__inner">
					<div className="rule__inner-item">
						<img
							className="rule__inner-item-img"
							src={img.img}
							alt="Rule Parrots' Fight Club"
						/>
						<div className="rule__inner-item-shadow" />
					</div>

					<div className="rule__block">
						<Title
							variant="h2"
							title_class="rule__title"
							animation={img.animation}
						>
							<span className="rule__title-span"></span>{" "}
							<span className="rule__title-stroke">
								{img.number}
							</span>
						</Title>
						<Paragraph
							paragraph_class="rule__paragraph"
							text={img.text}
						/>
					</div>
				</div>
			))}
		</div>
	);
};