import React from "react";
import { Paragraph, Title } from "..";

const StepsInfo = () => {
	const steps_info = [
		{
			percent: "1. Marketing Plan and Community Building",
			text: [
				"We need a solid plan to reach the moon! <br /> During this phase, we will be giving away 40 Mystery Boxes to the community.",
			],
			animation: { right: true, distance: "20px" },
		},
		{
			percent: "2. Mint Phase",
			text: [
				"You can purchase one or more crates! Hopefully, you'll get a goblin that isn’t too ugly!",
			],
			animation: { right: true, distance: "20px" },
		},
		{
			percent: "3. Open the Crates",
			text: "Finally, it’s time to reveal the goblin you purchased.",
			animation: { right: true, distance: "20px" },
		},
		{
			percent: "4. Opening the Mystery Boxes",
			text: [
				"If you’re lucky enough to own one of the 500 rarest goblins, you can open a Mystery Box! <br /> If you have a Mystery Box but no Goblin to open it... well, go find one of the 500 goblins that can!",
			],
			animation: { right: true, distance: "20px" },
		},
		{
			percent: "5. Opening of the Merchandise Shop!",
			text: "Let’s try to sell some useless, project-related items!",
			animation: { right: true, distance: "20px" },
		},
		{
			percent: "6. Let’s Start a DAO!",
			text: [
				"Let’s put our ideas together and decide the best actions to take to help this project thrive! <br /> Remember: if you own a Goblin, you’re part of the project!",
			],
			animation: { right: true, distance: "20px" },
		},
	];

	return (
		<div className="steps__info">
			{steps_info.map((si, i) => (
				<div key={i} className="steps__info-block">
					<Title
						variant="h3"
						title_class="steps__info-block-title"
						animation={si.animation}
					>
						<span className="steps__info-block-title-stroke">
							{si.percent}
						</span>

					</Title>
					<Paragraph
						paragraph_class="steps__info-block-paragraph"
						text={si.text}
					/>
				</div>
			))}
		</div>
	);
};

const StateDots = () => {
	const dots = [];

	for (let i = 0; i < 6; i++) {
		dots.push(
			<div key={i} className="steps__state">
				<div className="steps__state-dot" />
			</div>
		);
	}

	return <>{dots.map((d) => d)}</>;
};

export const Steps = () => {
	return (
		<div className="steps">
			<div className="steps__line" />
			<StateDots />
			<StepsInfo />
		</div>
	);
};