import React from "react";
import { Fade } from 'react-awesome-reveal';

export const Paragraph = ({ paragraph_class = "", text = "", animation }) => {
  return (
    <Fade {...animation}>
      {Array.isArray(text) ? (
        text.map((line, index) => {
          const formattedText = line.split("<br />").map((part, i) => (
            <React.Fragment key={i}>
              {part}
              {i < line.split("<br />").length - 1 && <br />}
            </React.Fragment>
          ));
          return (
            <p key={index} className={paragraph_class}>
              {formattedText}
            </p>
          );
        })
      ) : (
        <p className={paragraph_class}>{text}</p>
      )}
    </Fade>
  );
};